import _ from 'lodash'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Icon, Table, Dimmer, Loader } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'

export class Team extends Component {
    state = {
        active: true,
        teamId: '',
        teamName: '',
        matches: null
    }

    componentDidMount() {
        var param = this.props.location.pathname;
        param = param.replace('team/', '');
        param = param.replace('/', '');

        this.setState({ teamId: param });
        this.logPageHit(param);

        this.getTeamName(param);
        this.getResults(param);
    }

    logPageHit(id) {
        var pagename = 'Team-' + id;
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getTeamName(id) {
        fetch('api/Matches/TeamName/' + id)
            .then(res => res.text())
            .then(res => this.setState({ teamName: String(res) }))
            .catch(err => console.log("ERROR:", err));
    }

    getResults(id) {
        fetch('api/Matches/Team/' + id)
            .then(res => res.json())
            .then(res => this.setState({ matches: res }))
            .then(this.setState({ active: false }))
            .catch(err => console.log("ERROR:", err));
    }

    setTeamStyle(match, id) {
        if (id === 1) {
            if (match.team1Name === this.state.teamName && match.team1Score === 1)
                return "h5 green-text";
            if (match.team1Name === this.state.teamName && match.team1Score === 0)
                return "h5 red-text";
        }
        if (id === 2) {
            if (match.team2Name === this.state.teamName && match.team2Score === 1)
                return "h5 green-text";
            if (match.team2Name === this.state.teamName && match.team2Score === 0)
                return "h5 red-text";
        }

        return "h5 gray-text";
    }

    render() {
        const { matches, teamName } = this.state;

        return (
            <center>
                <Helmet>
                    <title>Team Results from the Minnesota High School Chess League</title>
                </Helmet>
                <h1> Team Stats for {teamName} </h1>

                <Dimmer active={this.state.active}>
                    <Loader>Loading</Loader>
                </Dimmer>

                {matches != null &&
                    <div>
                        <div style={{ display: 'inline-block' }}>
                            {matches.map(match =>
                                <div key={match.id}>
                                    <div className="border-top my-3"></div>

                                    <div>
                                        <span className={'h5'} style={{ color: 'rosybrown' }}>Round {match.thisRound} : </span>
                                        <span className={this.setTeamStyle(match, 1)}>{match.team1Name}</span>
                                        <span className={'h5 score-text'}>({match.team1Score})</span>

                                        <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>

                                        <span className={this.setTeamStyle(match, 2)}>{match.team2Name}</span>
                                        <span className={'h5 score-text'}>({match.team2Score})</span>
                                    </div>

                                    <Table collapsing celled striped unstackable className="purple" style={{ display: 'inline-table' }} >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Board</Table.HeaderCell>
                                                <Table.HeaderCell>{match.team1Name}</Table.HeaderCell>
                                                <Table.HeaderCell>Result</Table.HeaderCell>
                                                <Table.HeaderCell>{match.team2Name}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {_.map(match.games, ({ id, boardNum, player1NameAndColor, player1Id, player2Id, result, player2NameAndColor, player1Score, player2Score, player1ExcludeFromScore, player2ExcludeFromScore }) => (
                                                <Table.Row key={id}>
                                                    <Table.Cell className="text-center">{boardNum}</Table.Cell>
                                                    {player1Score === 1 ?
                                                        (
                                                            <Table.Cell positive>
                                                                <Icon name='checkmark' />
                                                                {player1ExcludeFromScore ? '# ' : ''}
                                                                <Link to={`/player/${player1Id}`}>{player1NameAndColor}</Link>
                                                            </Table.Cell>
                                                        ) : (
                                                            <Table.Cell>
                                                                {player1ExcludeFromScore ? '# ' : ''}
                                                                <Link to={`/player/${player1Id}`}>{player1NameAndColor}</Link>
                                                            </Table.Cell>
                                                        )}

                                                    <Table.Cell className="text-center">{result}</Table.Cell>

                                                    {player2Score === 1 ?
                                                        (
                                                            <Table.Cell positive>
                                                                <Icon name='checkmark' />
                                                                {player2ExcludeFromScore ? '# ' : ''}
                                                                <Link to={`/player/${player2Id}`}>{player2NameAndColor}</Link>
                                                            </Table.Cell>
                                                        ) : (
                                                            <Table.Cell>
                                                                {player2ExcludeFromScore ? '# ' : ''}
                                                                <Link to={`/player/${player2Id}`}>{player2NameAndColor}</Link>
                                                            </Table.Cell>
                                                        )}

                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    <div style={{ marginBottom: '50px' }} />
                                </div>)}
                        </div>
                    </div>
                }
            </center>
        );
    }
}
