import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from "react-helmet";

export class About extends Component {
    state = {
        seasonTitle: ''
    }

    componentDidMount() {
        this.logPageHit();
        this.getSettings();
    }

    logPageHit() {
        var pagename = 'About';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getSettings() {
        fetch('api/Admin/Setting/1')
            .then(res => res.json())
            .then(res => this.setState({ seasonTitle: res }))
            .catch(err => console.log("ERROR:", err));
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>About the Minnesota High School Chess League</title>
                </Helmet>
                <h1>Minnesota High School Chess League</h1>
                <br />
                <div className="blue-text">Welcome to the Minnesota High School Chess League.  We are currently in our 8th season.</div>
                <br />
                <div className="blue-text">The Minnesota High School Chess League is a league that is offering high school team competition played on <a href="https://www.Lichess.org">Lichess.org</a>.</div>
                <br />
                <div className="blue-text"> If you are interested in participating in the league in future seasons, please contact the league at: <a href='mailto:mnhschessleague@gmail.com'>mnhschessleague@gmail.com</a>.</div>
                <br />
                <div className="blue-text">League Director for the {this.state.seasonTitle} Season:
                    <br />
                    <span className='font-weight-bold blue-text'>Jackson Wahl</span>
                </div>
            </div>
        );
    }
}
