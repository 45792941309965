import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { GOOGLE_ANALYTICS_API } from './constants';
import { Form, Button, Input, Alert } from 'reactstrap';
import Select from 'react-select'
import '../styles/editpage.css';

const resultOptions = [
    { value: '', label: '' },
    { value: 'W', label: '1 - 0' },
    { value: 'D', label: '0.5 - 0.5' },
    { value: 'L', label: '0 - 1' }
]

const customStyles = {
    option: (base, state) => ({
        ...base,
        borderBottom: "1px dotted purple",
        color: "black",
        textAlign: "left",
        cursor: "pointer",
        width: "300px"
    }),
    div: (base, state) => ({
        ...base,
        color: "purple",
    }),
    input: () => ({
        fontSize: 13
    }),
    control: (base, state) => ({
        ...base,
        color: "black",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: "blue"
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        padding: 0
    })
};

export class EditAlternateGame extends Component {
    state = {
        g1p1: null,
        g1p2: null,
        g1r: null,
        p1Exclude: false,
        p2Exclude: false,
        playersList: [],
        redirect: false,
        boardNum: 0,
        round: 0,
        alertVisible: false,
    }

    componentWillMount() {
        if (localStorage.getItem("mhscl-user") === null) {
            console.log("Redirect", "true");
            this.setState({ redirect: true });
        } else {
            console.log("Redirect", "false");
        }
    }

    componentDidMount() {
        this.logPageHit();

        this.setState({ gameObj: this.props.location.gameObj });
        this.setState({ round: this.props.location.round });
        this.getPlayers();
        this.loadGameResults();
    }

    goBack = () => {
        this.props.history.push('/Games')
    }

    handleSuccessAlert() {
        this.setState({
            alertVisible: true
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 3000);
    }

    logPageHit() {
        var pagename = 'EditAlternateGame';
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);

        var url = 'api/Admin/LogPageHit/' + pagename + '/' + localStorage.getItem('mhscl-user');
        fetch(url, { method: 'post' });
    }

    loadGameResults() {
        var myGame = this.props.location.gameObj;
        console.log("Game", myGame);
        this.setState({
            g1r: resultOptions.find(obj => obj.label === myGame.result),
            p1Exclude: myGame.player1ExcludeFromScore,
            p2Exclude: myGame.player2ExcludeFromScore,
            boardNum: myGame.boardNum
        });
    }

    loadSavedPlayers() {
        var myGame = this.props.location.gameObj;
        this.setState({
            g1p1: this.state.playersList.find(obj => obj.value === parseInt(myGame.player1Id)),
            g1p2: this.state.playersList.find(obj => obj.value === parseInt(myGame.player2Id))
        });
    }

    getPlayers() {
        fetch('api/Admin/Players')
            .then(res => res.json())
            .then(res => this.setState({ playersList: res }))
            .then(() => { this.loadSavedPlayers() })
            .catch(err => console.log("ERROR:", err));
    }

    saveGame() {
        var byePlayer = 382;
        var myGame = this.props.location.gameObj;
        var myMatchId = myGame.matchId;

        //If Match ID = -1, then assume this is a new game
        if (myGame.id === 0) {
            myMatchId = this.props.location.round;
        }
        console.log("Save: ", myMatchId);
        if (myGame.player1Id === null) myGame.player1Id = byePlayer;
        if (myGame.player2Id === null) myGame.player2Id = byePlayer;

        fetch('api/Admin/SaveAlternateGame/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                matchId: myMatchId,
                boardNum: Number(myGame.boardNum),
                id: myGame.id,
                player1Id: myGame.player1Id.toString(),
                player1Color: "W",
                player1Score: Number(myGame.player1Score),
                player1Team: 0,
                player1ExcludeFromScore: myGame.player1ExcludeFromScore,
                player2Id: myGame.player2Id.toString(),
                player2Color: "B",
                player2Score: Number(myGame.player2Score),
                player2Team: 0,
                player2ExcludeFromScore: myGame.player2ExcludeFromScore,
            })
        })
            .then(res => res.json())
            .then((res) => {
                var newid = res;
                this.props.location.gameObj.id = parseInt(newid);
            })
            .then(this.handleSuccessAlert())
            .catch(err => console.log(err));
    }

    submitEdit = e => {
        e.preventDefault();
        this.saveGame();
    }


    handlePlayerChange = (e, action) => {
        if (e !== null) {
            //console.log(e.value + " (" + e.label + ")");
            var newPlayer = e.value;
            switch (action.name) {
                case "game1player1":
                    this.props.location.gameObj.player1Id = newPlayer;
                    this.setState({ g1p1: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game1player2":
                    this.props.location.gameObj.player2Id = e.value;
                    this.setState({ g1p2: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                default:
                    break;
            }
        }
    }

    handleBoardChange = (e) => {
        if (e !== null) {
            var newValue = e.target.value;
            console.log(e.target.name + ":" + newValue);

            this.props.location.gameObj.boardNum = newValue;
            this.setState({ boardNum: newValue });
        }
    }

    handleExcludeChange = (e) => {
        if (e !== null) {
            var newValue = e.target.checked;
            console.log(e.target.name + ":" + newValue);
            switch (e.target.name) {
                case "player1Exclude":
                    this.props.location.gameObj.player1ExcludeFromScore = newValue;
                    this.setState({ p1Exclude: newValue });
                    break;
                case "player2Exclude":
                    this.props.location.gameObj.player2ExcludeFromScore = newValue;
                    this.setState({ p2Exclude: newValue });
                    break;
                default:
                    break;
            }
        }
    }

    handleResultChange = (e, action) => {
        if (e !== null) {
            //console.log(e.value + " (" + e.label + ")");
            var thisResult = e.value;
            var valWin = "1 - 0";
            var valLoss = "0 - 1";
            var valDraw = "0.5 - 0.5";
            var valResult = "";

            if (thisResult === "W") {
                valResult = valWin;
                this.props.location.gameObj.player1Score = 1;
                this.props.location.gameObj.player2Score = 0;
            } else if (thisResult === "L") {
                valResult = valLoss;
                this.props.location.gameObj.player1Score = 0;
                this.props.location.gameObj.player2Score = 1;
            } else {
                valResult = valDraw
                this.props.location.gameObj.player1Score = 0.5;
                this.props.location.gameObj.player2Score = 0.5;
            }

            this.props.location.gameObj.result = valResult;

            switch (action.name) {
                case "game1result":
                    this.setState({ g1r: resultOptions.find(obj => obj.value === valResult) });
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect
                to="/Admin"
            />;
        }

        var myGame = this.props.location.gameObj;
        var title = "Edit Alternate Game (#" + myGame.id + ")";
        if (Number(myGame.id) === 0)
            title = "Add Alternate Game (Round " + this.props.location.round + ")";

        return (
            <div style={{ textAlign: 'center' }} >
                <Helmet>
                    <title>MHSCL Admin - Edit Alternate Game</title>
                </Helmet>

                <Alert color="success" isOpen={this.state.alertVisible}>
                    Your changes have been successfully saved
                </Alert>

                <h1>{title}</h1>

                <Form onSubmit={this.submitEdit}>

                    <div style={{ marginTop: '50px' }}>
                        <table className="edit-wholetable">
                            <tbody>
                                <tr>
                                    <th className="edit-th">Bd</th>
                                    <th className="edit-th">White</th>
                                    <th className="edit-th">Exclude Score</th>
                                    <th className="edit-th">Result</th>
                                    <th className="edit-th">Exclude Score</th>
                                    <th className="edit-th">Black</th>
                                </tr>
                                <tr>
                                    <td className="td-font td-input-sm">
                                        <Input
                                            name="boardNumber"
                                            defaultValue={myGame === null ? '' : myGame.boardNum}
                                            onChange={this.handleBoardChange}
                                        />
                                    </td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game1player1"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g1p1}
                                        /></td>
                                    <td className="edit-table td-font td-checkbox">
                                        <Input
                                            name="player1Exclude"
                                            type="checkbox"
                                            className="edit-checkbox"
                                            checked={this.state.p1Exclude}
                                            onChange={this.handleExcludeChange}
                                        />
                                    </td>
                                    <td className="edit-table col-width-sm">
                                        <Select
                                            name="game1result"
                                            onChange={this.handleResultChange}
                                            options={resultOptions}
                                            value={this.state.g1r}
                                        /></td>
                                    <td className="edit-table td-font td-checkbox">
                                        <Input
                                            name="player2Exclude"
                                            type="checkbox"
                                            className="edit-checkbox"
                                            checked={this.state.p2Exclude}
                                            onChange={this.handleExcludeChange}
                                        />
                                    </td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game1player2"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g1p2}
                                        /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="center-div" style={{ marginTop: '25px' }}>
                        <Button color="success" type="submit">Save</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="info" onClick={this.goBack}>Close</Button>
                    </div>
                </Form>
            </div >
        );
    }
}
