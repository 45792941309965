import _ from 'lodash'
import React, { Component } from 'react';
import { GOOGLE_ANALYTICS_API } from './constants';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap';
import { Table, Dimmer, Loader } from 'semantic-ui-react'

export class ViewTeams extends Component {
    state = {
        active: true,
        newTeam: null,
        data: [],
        admin: false,
        seasonTitle: ''
    }

    componentDidMount() {
        this.logPageHit();
        this.getBlankTeam();
        this.getTeams();
        this.getSettings();

        if (localStorage.getItem("mhscl-user") !== null) {
            this.setState({ admin: true });
        }
    }

    getSettings() {
        fetch('api/Admin/Setting/1')
            .then(res => res.json())
            .then(res => this.setState({ seasonTitle: res }))
            .catch(err => console.log("ERROR:", err));
    }

    getTeams() {
        fetch('api/Admin/Teams')
            .then(res => res.json())
            .then(res => this.setState({ data: res }))
            .then(this.setState({ active: false }))
            .catch(err => console.log("ERROR:", err));
    }

    getBlankTeam() {
        fetch('api/Admin/BlankTeam/')
            .then(res => res.json())
            .then(res => this.setState({ newTeam: res }))
            .catch(err => console.log("ERROR:", err))
    }

    logPageHit() {
        var pagename = 'ViewTeams';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
            return;
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });
    }

    render() {
        const { column, data, direction, seasonTitle } = this.state

        return (
            <div style={{ textAlign: 'center' }}>
                <Helmet>
                    <title>Listing of teams signed up for the {seasonTitle} Minnesota High School Chess League</title>
                </Helmet>
                <h1>List of Teams</h1>

                {this.state.admin &&
                    <div>
                        <Link to={{
                            pathname: '/EditTeam',
                            teamObj: this.state.newTeam
                        }}>
                            <Button
                                className="btn-sm"
                                color="primary">
                                Add New Team
                        </Button>
                        </Link>
                    </div>
                }
                <Dimmer active={this.state.active}>
                    <Loader>Loading</Loader>
                </Dimmer>

                <div style={{ display: 'inline-block', width:'800px', marginTop:'15px' }}>
                    <Table sortable celled fixed striped style={{ textAlign: 'center' }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    className="tableHdr"
                                    sorted={column === 'teamName' ? direction : null}
                                    onClick={this.handleSort('teamName')}
                                >Team Name</Table.HeaderCell>

                                <Table.HeaderCell
                                    className="tableHdr"
                                    sorted={column === 'avgRating' ? direction : null}
                                    onClick={this.handleSort('avgRating')}
                                >Avg Rating</Table.HeaderCell>

                                <Table.HeaderCell
                                    className="tableHdr"
                                    sorted={column === 'points' ? direction : null}
                                    onClick={this.handleSort('points')}
                                >Points</Table.HeaderCell>

                                <Table.HeaderCell
                                    className="tableHdr"
                                    sorted={column === 'playoffTier' ? direction : null}
                                    onClick={this.handleSort('playoffTier')}
                                >Playoff Tier</Table.HeaderCell>

                                <Table.HeaderCell
                                    className="tableHdr"
                                    sorted={column === 'color' ? direction : null}
                                    onClick={this.handleSort('color')}
                                >Playoff Color</Table.HeaderCell>

                                {this.state.admin &&
                                    <Table.HeaderCell style={{ width: '65px' }}></Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            {data.map(team => (
                                <Table.Row key={team.id}>
                                    <Table.Cell>
                                        <Link to={`/player/${team.id}`}>{team.teamName}</Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {team.avgRating}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {team.points}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {team.playoffTier}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {team.color}
                                    </Table.Cell>
                                    {this.state.admin &&
                                        <Table.Cell>
                                            <span>
                                                <Link to={{
                                                    pathname: '/EditTeam',
                                                    teamObj: team
                                                }}>
                                                    <Button
                                                        className="btn-sm"
                                                        color="primary">
                                                        Edit
                                                </Button>
                                                </Link>
                                            </span>
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}