import './styles/semantic-ui-css/semantic.min.css'
import './styles/custom.css'

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Rules } from './components/Rules';
import { Schedule } from './components/Schedule';
import { About } from './components/About';
import { Players } from './components/Players';
import { Games } from './components/Games';
import { Team } from './components/Team';
import { Standings } from './components/Standings';
import { Player } from './components/Player';
import { Instructions } from './components/Instructions';
import { Admin } from './components/Admin';
import { ViewTeams } from './components/ViewTeams';
import { EditTeam } from './components/EditTeam';
import { EditMatch } from './components/EditMatch';
import { EditPlayer } from './components/EditPlayer';
import { EditAlternateGame } from './components/EditAlternateGame';
import { Analytics } from './components/Analytics';
import ClearCache from 'react-clear-cache'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/standings' component={Standings} />
                <Route path='/games' component={Games} />
                <Route path='/team' component={Team} />
                <Route path='/players' component={Players} />
                <Route path='/rules' component={Rules} />
                <Route path='/schedule' component={Schedule} />
                <Route path='/instructions' component={Instructions} />
                <Route path='/about' component={About} />
                <Route path='/admin' component={Admin} />
                <Route path='/viewteams' component={ViewTeams} />
                <Route path='/editTeam' component={EditTeam} />
                <Route path='/editmatch' component={EditMatch} />
                <Route path='/editplayer' component={EditPlayer} />
                <Route path='/editalternategame' component={EditAlternateGame} />
                <Route path='/analytics' component={Analytics} />
                <Route exact path="/player/:playerId" component={Player} />

                <ClearCache duration="5000" auto="true">
                    {({ isLatestVersion, emptyCacheStorage }) => (
                        <div style={{ display: 'none' }}>
                            Latest version: {isLatestVersion ? 'Yes' : emptyCacheStorage()}
                        </div>
                    )}
                </ClearCache>
            </Layout>

        );
    }
}
