import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'


export class Instructions extends Component {

    componentDidMount() {
        this.logPageHit();
    }

    logPageHit() {
        var pagename = 'Instructions';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        return (
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <Helmet>
                    <title>Instructions for the Minnesota High School Chess League</title>
                </Helmet>
                <h1>Player Instructions</h1>

                <div className='font-weight-bold'>Creating an account with the correct username:</div>
                <ol>
                    <li className="blue-text">Go to lichess.org and click "Sign in" at the top right.</li>
                    <li className="blue-text">On the sign-in page, click "Register" on the right.</li>
                    <li className="blue-text">Create a username in the format "Schoolname-Lastname" (e.g. Blake-Wahl). It is very important that you use this format.
                                    This is how the Director will check results.
                        Email confirmation is required for lichess accounts to be used.</li>
                </ol>

                <div className='font-weight-bold'>Challenging other users against whom you are paired:</div>

                <ol>
                    <li className="blue-text">Go to your Lichess homepage.</li>
                    <li className="blue-text">Check if you have been challenged by your opponent. If so, accept the challenge.</li>
                    <li className="blue-text">If not, go to the URL box and replace your username with your opponent's username.
                        The URL will be of the form lichess.org/@/(username).</li>
                    <li className="blue-text">In your opponent's name banner, click the icon with two swords crossed. </li>
                    <li className="blue-text">In the pop-up "Challenge to a game" that appears, set the variant to "Standard",
                        the time control to "real time", the minutes to 30, the increment to 3, and the game type to "casual".
                        Click the king of the color you are assigned to play. </li>
                    <li className="blue-text">Wait for your opponent to accept the challenge.</li>
                    <li className="blue-text">If your opponent has not accepted your challenge by 3:40pm, let your coach know.
                        If your opponent has not appeared by 3:45, you may be awarded a forfeit win.</li>
                </ol>

                <div className='font-weight-bold'>Important changes to these rules:</div>
                <ol>
                    <li className="blue-text">Students are welcome to play additional games on their Lichess account.
                            In fact, it is a good idea to familiarize yourself with the site.
                        However, please only play one game against your assigned opponent.</li>
                    <li className="blue-text">Either player may initiate the challenge. It does not matter what color you are assigned;
                            just make sure you select the correct king when challenging your opponent.</li>
                </ol>

            </div>
        );
    }
}
