import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'

export class Analytics extends Component {
    state = {
        data: [],
        dataViews: [],
        dataUsers: [],
        admin: false,
        seasonTitle: ''
    }

    componentDidMount() {
        this.logPageHit();
        this.getAccessLog();
        this.getDailyViewLog();
        this.getDailyUserLog();
        this.getSettings();

        if (localStorage.getItem("mhscl-user") !== null) {
            this.setState({ admin: true });
        }
    }

    getSettings() {
        fetch('api/Admin/Setting/1')
            .then(res => res.json())
            .then(res => this.setState({ seasonTitle: res }))
            .catch(err => console.log("ERROR:", err));
    }

    getAccessLog() {
        fetch('api/Admin/AccessLog')
            .then(res => res.json())
            .then(res => this.setState({ data: res }))
            .catch(err => console.log("ERROR:", err));
    }

    getDailyViewLog() {
        fetch('api/Admin/DailyViewLog')
            .then(res => res.json())
            .then(res => this.setState({ dataViews: res }))
            .catch(err => console.log("ERROR:", err));
    }

    getDailyUserLog() {
        fetch('api/Admin/DailyUserLog')
            .then(res => res.json())
            .then(res => this.setState({ dataUsers: res }))
            .catch(err => console.log("ERROR:", err));
    }

    logPageHit() {
        var pagename = 'Analytics';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        const { data, dataViews, dataUsers, seasonTitle } = this.state

        return (
            <div>
                <Helmet>
                    <title>Analytics for the {seasonTitle} Minnesota High School Chess League</title>
                </Helmet>

                <h2>Page Views</h2>

                <Table celled striped style={{width:'300px'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="tableHdr">Date</Table.HeaderCell>
                            <Table.HeaderCell className="tableHdr">Count</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dataViews.map(logItem => (
                            <Table.Row key={logItem.id}>
                                <Table.Cell>
                                    {logItem.logDayDisplay}
                                </Table.Cell>
                                <Table.Cell>
                                    {logItem.cnt}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <h2>Distinct Users</h2>

                <Table celled striped style={{ width: '300px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="tableHdr">Date</Table.HeaderCell>
                            <Table.HeaderCell className="tableHdr">Count</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dataUsers.map(logItem => (
                            <Table.Row key={logItem.id}>
                                <Table.Cell>
                                    {logItem.logDayDisplay}
                                </Table.Cell>
                                <Table.Cell>
                                    {logItem.cnt}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <h2>Full Log</h2>

                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="tableHdr">ID</Table.HeaderCell>
                            <Table.HeaderCell className="tableHdr">Timestamp</Table.HeaderCell>
                            <Table.HeaderCell className="tableHdr">Page</Table.HeaderCell>
                            <Table.HeaderCell className="tableHdr">IP Address</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map(logItem => (
                            <Table.Row key={logItem.id}>
                                <Table.Cell>
                                    {logItem.id}
                                </Table.Cell>
                                <Table.Cell>
                                    {logItem.pageHitTime}
                                </Table.Cell>
                                <Table.Cell>
                                    {logItem.webPage}
                                </Table.Cell>
                                <Table.Cell>
                                    {logItem.ipAddress}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
