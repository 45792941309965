export const GOOGLE_ANALYTICS_API = 'UA-154896743-1';


export const customStyles = {
    option: (base, state) => ({
        ...base,
        borderBottom: "1px dotted purple",
        color: "black",
        textAlign: "left",
        cursor: "pointer",
        width: "300px"
    }),
    div: (base, state) => ({
        ...base,
        color: "purple",
    }),
    input: () => ({
        fontSize: 13
    }),
    control: (base, state) => ({
        ...base,
        color: "black",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: "blue"
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        padding: 0
    })
};