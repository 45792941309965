import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Icon, Table, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Button as Button2 } from 'reactstrap';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'

export class Games extends Component {
    state = {
        column: null,
        data: [],
        direction: null,
        myRound: 0,
        active: true,
        admin: false,
        newGame: null,
        newMatch: null,
    }

    componentDidMount() {
        this.logPageHit();
        this.handleRound(0);
        this.getBlankGame();
        this.getBlankMatch();

        if (localStorage.getItem("mhscl-user") !== null) {
            this.setState({ admin: true });
        }
    }

    logPageHit() {
        var pagename = 'Results';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    handleRound(rd) {
        if (rd === 0) {
            fetch('api/Matches/MaxRound')
                .then(res => res.json())
                .then(res => this.getResults(res))
                //.then(res => this.setState({ maxRound: res }))
                .catch(err => console.log("ERROR:", err));
        } else {
            this.getResults(rd);
        }
    }

    getBlankMatch() {
        fetch('api/Matches/BlankMatch/')
            .then(res => res.json())
            .then(res => this.setState({ newMatch: res }))
            .catch(err => console.log("ERROR:", err))
    }

    getBlankGame() {
        fetch('api/Games/BlankGame/')
            .then(res => res.json())
            .then(res => this.setState({ newGame: res }))
            .catch(err => console.log("ERROR:", err))
    }

    getResults(rd) {
        fetch('api/Matches/' + rd)
            .then(res => res.json())
            .then(res => this.setState({ data: res }))
            .then(this.setState({ myRound: rd }))
            .then(this.setState({ active: false }))
            .catch(err => console.log("ERROR:", err));
    }

    render() {
        const { data } = this.state;
        //let maxRound = this.maxRound;

        return (
            <div style={{ textAlign: 'center' }}>
                <Helmet>
                    <title>Results from the Minnesota High School Chess League</title>
                </Helmet>
                <h1>Round-by-Round Results</h1>

                <Dimmer active={this.state.active}>
                    <Loader>Loading</Loader>
                </Dimmer>

                <div style={{ display: 'inline-block' }}>
                    <div style={{ paddingBottom: '40px' }}>
                        <Button.Group>
                            <Button className='btn-tiny' color={this.state.myRound === 1 ? 'grey' : 'blue'} onClick={() => { this.handleRound(1) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R1</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 2 ? 'grey' : 'blue'} onClick={() => { this.handleRound(2) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R2</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 3 ? 'grey' : 'blue'} onClick={() => { this.handleRound(3) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R3</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 4 ? 'grey' : 'blue'} onClick={() => { this.handleRound(4) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R4</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 5 ? 'grey' : 'blue'} onClick={() => { this.handleRound(5) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R5</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 6 ? 'grey' : 'blue'} onClick={() => { this.handleRound(6) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R6</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 7 ? 'grey' : 'blue'} onClick={() => { this.handleRound(7) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R7</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 8 ? 'grey' : 'blue'} onClick={() => { this.handleRound(8) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R8</Button>
                            <Button className='btn-tiny' color={this.state.myRound === 9 ? 'grey' : 'blue'} onClick={() => { this.handleRound(9) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>R9</Button>
                        </Button.Group>
                        {/*<div style={{ paddingTop: '5px' }} />*/}
                        {/*<Button.Group>*/}
                        {/*    <Button className='btn-tiny' color={this.state.myRound === 8 ? 'grey' : 'blue'} onClick={() => { this.handleRound(8) }}*/}
                        {/*        style={{ border: '1px solid rosybrown', padding: '10px' }}>P-1</Button>*/}
                        {/*    <Button className='btn-tiny' color={this.state.myRound === 9 ? 'grey' : 'blue'} onClick={() => { this.handleRound(9) }}*/}
                        {/*        style={{ border: '1px solid rosybrown', padding: '10px' }}>P-2</Button>*/}
                        {/*    <Button className='btn-tiny' color={this.state.myRound === 10 ? 'grey' : 'blue'} onClick={() => { this.handleRound(10) }}*/}
                        {/*        style={{ border: '1px solid rosybrown', padding: '10px' }}>P-3</Button>*/}
                        {/*</Button.Group>*/}
                    </div>
                    {data.map(match =>
                        <div key={match.id}>
                            <div>
                                <span className={match.team1Name === 'White' ? 'td-hide' : 'h4'}>{match.team1Name}</span>
                                <span className={match.team1Name === 'White' ? 'td-hide' : 'h5 score-text'}>({match.team1Score})</span>
                                <span className={match.team1Name === 'White' ? 'td-hide' : ''}>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                                <span className={match.team1Name === 'White' ? 'td-hide' : 'h4'}>{match.team2Name}</span>
                                <span className={match.team1Name === 'White' ? 'td-hide' : 'h5 score-text'}>({match.team2Score})</span>
                                <span className={match.team1Name === 'White' ? 'h4' : 'td-hide'}>Alternates</span>
                            </div>

                            {this.state.admin &&
                                <div>
                                    {match.team1Name === 'White' ?
                                        <span>
                                            <Link to={{
                                                pathname: '/EditAlternateGame',
                                                gameObj: this.state.newGame,
                                                round: match.thisRound
                                            }}>
                                                <Button2
                                                    className="btn-sm btn-edit"
                                                    color="primary">
                                                    Add Alternate Game
                                                </Button2>
                                            </Link>
                                        </span>
                                        :
                                        <span>
                                            <Link to={{
                                                pathname: '/EditMatch',
                                                matchObj: match,
                                                isNew: false
                                            }}>
                                                <Button2
                                                    className="btn-sm btn-edit"
                                                    color="primary">
                                                    Edit Match
                                                </Button2>
                                            </Link>
                                        </span>
                                    }
                                </div>
                            }
                            <Table collapsing celled striped unstackable className="purple" style={{ display: 'inline-table' }} >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Board</Table.HeaderCell>
                                        <Table.HeaderCell>{match.team1Name}</Table.HeaderCell>
                                        <Table.HeaderCell>Result</Table.HeaderCell>
                                        <Table.HeaderCell>{match.team2Name}</Table.HeaderCell>
                                        {match.team1Name === 'White' ? <Table.HeaderCell></Table.HeaderCell> : ''}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {match.games.map(game => (
                                        <Table.Row key={game.id}>
                                            <Table.Cell className="text-center">{game.boardNum}</Table.Cell>
                                            {game.player1Score === 1 ?
                                                (
                                                    <Table.Cell positive>
                                                        <Icon name='checkmark' />
                                                        {game.player1ExcludeFromScore ? '# ' : ''}
                                                        <Link to={`/player/${game.player1Id}`}>{game.player1NameAndColor}</Link>
                                                    </Table.Cell>
                                                ) : (
                                                    <Table.Cell>
                                                        {game.player1ExcludeFromScore ? '# ' : ''}
                                                        <Link to={`/player/${game.player1Id}`}>{game.player1NameAndColor}</Link>
                                                    </Table.Cell>
                                                )}
                                            <Table.Cell className="text-center">{game.result}</Table.Cell>
                                            {game.player2Score === 1 ?
                                                (
                                                    <Table.Cell positive>
                                                        <Icon name='checkmark' />
                                                        {game.player2ExcludeFromScore ? '# ' : ''}
                                                        <Link to={`/player/${game.player2Id}`}>{game.player2NameAndColor}</Link>
                                                    </Table.Cell>
                                                ) : (
                                                    <Table.Cell>
                                                        {game.player2ExcludeFromScore ? '# ' : ''}
                                                        <Link to={`/player/${game.player2Id}`}>{game.player2NameAndColor}</Link>
                                                    </Table.Cell>
                                                )}
                                            {this.state.admin && match.team1Name === 'White' ?
                                                <Table.Cell>
                                                    <span>
                                                        <Link to={{
                                                            pathname: '/EditAlternateGame',
                                                            gameObj: game
                                                        }}>
                                                            <Button2
                                                                className="btn-sm"
                                                                color="primary">
                                                                Edit
                                                            </Button2>
                                                        </Link>
                                                    </span>
                                                </Table.Cell> : ''
                                            }
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            <div style={{ marginBottom: '50px' }} />
                        </div>)}
                </div>
                {this.state.admin &&
                    <div>
                        <hr className='hr-purple' />
                        <Link to={{
                            pathname: '/EditMatch',
                            matchObj: this.state.newMatch,
                            isNew: true
                        }}>
                            <Button2
                                className="btn-sm"
                                color="primary">
                                Create New Match
                            </Button2>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to={{
                            pathname: '/EditAlternateGame',
                            gameObj: this.state.newGame,
                            round: this.state.myRound
                        }}>
                            <Button2
                                className="btn-sm"
                                color="primary">
                                Add Alternate Game
                            </Button2>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to={{ pathname: '/Players' }}>
                            <Button2
                                className="btn-sm"
                                color="primary">
                                Edit Players
                            </Button2>
                        </Link>
                        <hr className='hr-purple' />
                    </div>
                }
                <div style={{ paddingTop: '25px', paddingBottom: '50px' }} className="font-italic">
                    R = Regular season round/week
                    <br />
                    P = Playoffs
                    <br />
                    # = Result not counted in standings
                </div>
            </div>
        );
    }
}
