import React, { Component } from 'react';
import { GOOGLE_ANALYTICS_API } from './constants';
import ReactGA from 'react-ga';
import GoogleLogin from 'react-google-login';
import GoogleLogout from 'react-google-login';
import { Link } from "react-router-dom";

export class Admin extends Component {
    state = {
        isAuthenticated: false
    }

    componentDidMount() {
        this.logPageHit();

        this.isAuthenticated();
    }

    logPageHit() {
        var pagename = 'Admin';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    isAuthenticated() {
        if (localStorage.getItem("mhscl-user") === null) {
            this.setState({ isAuthenticated: false });
        } else {
            this.setState({ isAuthenticated: true });
        }
    }

    successfulLogout = response => {
        console.log("successful-logout", response);
        localStorage.removeItem("mhscl-user");
        this.setState({ isAuthenticated: false });
    }

    failedLogout = response => {
        console.log("failed-logout", response);
    }

    failedGoogle = response => {
        console.log("failure", response);
    }

    responseGoogle = response => {
        console.log("success", response);

        var profile = response.getBasicProfile();
        var email = profile.getEmail();
        console.log('Email: ' + email);

        //Check database to see if this user is a valid user
        fetch('api/Admin/Authenticate/' + email)
            .then(res => res.json())
            .then((data) => {
                if (data === 1) {
                    localStorage.setItem("mhscl-user", email);
                }
                else {
                    alert("You are not a valid administrator");
                    localStorage.removeItem("mhscl-user");
                }
            })
            .catch(err => console.log("ERROR:", err));

        this.isAuthenticated();
    }

    render() {
        return (
            <center>
                {this.state.isAuthenticated === true ?
                    (
                        <div style={{ display: 'none' }}>
                            <GoogleLogin
                                clientId="120527426330-jjlpqg7rtasutuvb2dnhpchthnh560ad.apps.googleusercontent.com"
                                onSuccess={this.responseGoogle}
                                onFailure={this.failedGoogle}
                                isSignedIn={true}
                            />
                        </div>
                    ) : (
                        <div style={{ display: 'all' }}>
                            <GoogleLogin
                                clientId="120527426330-jjlpqg7rtasutuvb2dnhpchthnh560ad.apps.googleusercontent.com"
                                onSuccess={this.responseGoogle}
                                onFailure={this.failedGoogle}
                                isSignedIn={true}
                            />
                        </div>
                    )}
                <div>
                    &nbsp;&nbsp;
                    {this.state.isAuthenticated &&
                        <GoogleLogout
                            clientId="120527426330-jjlpqg7rtasutuvb2dnhpchthnh560ad.apps.googleusercontent.com"
                            buttonText="Logout"
                            onSuccess={this.successfulLogout}
                            onFailure={this.failedLogout}
                        />
                    }
                </div>
                {this.state.isAuthenticated &&
                    <div className="jumbotron" style={{ padding: '20px', width: '600px', marginTop: '50px' }}>
                        <div className="jumbotron-text"><Link to={`/Games/`}>Matches</Link> - Edit Matches and Games</div>
                        <div className="jumbotron-text"><Link to={`/ViewTeams/`}>Teams</Link> - View and Edit Teams</div>
                        <div className="jumbotron-text"><Link to={`/Players/`}>Players</Link> - Edit and Add Players</div>
                        <div className="jumbotron-text"><Link to={`/Analytics/`}>Analytics</Link> - View Page View Log</div>
                    </div>
                }
            </center>
        );
    }
}