import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet'
import { GOOGLE_ANALYTICS_API, customStyles } from './constants';
import { Form, Input, Button, Alert } from 'reactstrap';
import Select from 'react-select'
import '../styles/editpage.css';

const resultOptions = [
    { value: '', label: '' },
    { value: 'W', label: '1 - 0' },
    { value: 'D', label: '0.5 - 0.5' },
    { value: 'L', label: '0 - 1' },
    { value: 'W2', label: '1 - 1' }
]

export class EditMatch extends Component {
    state = {
        matchObj: null,
        team1: null,
        team2: null,
        g1p1: null,
        g1p2: null,
        g2p1: null,
        g2p2: null,
        g3p1: null,
        g3p2: null,
        g4p1: null,
        g4p2: null,
        g1r: null,
        g2r: null,
        g3r: null,
        g4r: null,
        teamsList: [],
        playersList: [],
        alertVisible: false,
        alertText: '',
        alertColor: '',
        redirect: false
    }

    componentWillMount() {
        if (localStorage.getItem("mhscl-user") === null) {
            console.log("Redirect", "true");
            this.setState({ redirect: true });
        } else {
            console.log("Redirect", "false");
        }
    }

    componentDidMount() {
        this.logPageHit();

        this.setState({ matchObj: this.props.location.matchObj });
        this.getTeams();
        this.getPlayers();
        this.loadGamesResults();
    }

    goBack = () => {
        this.props.history.push('/Games')
    }

    logPageHit() {
        var pagename = 'EditMatch';
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);

        var url = 'api/Admin/LogPageHit/' + pagename + '/' + localStorage.getItem('mhscl-user');
        fetch(url, { method: 'post' });
    }

    loadGamesResults() {
        var myMatch = this.props.location.matchObj;
        this.setState({ g1r: resultOptions.find(obj => obj.label === myMatch.games[0].result) });
        this.setState({ g2r: resultOptions.find(obj => obj.label === myMatch.games[1].result) });
        this.setState({ g3r: resultOptions.find(obj => obj.label === myMatch.games[2].result) });
        this.setState({ g4r: resultOptions.find(obj => obj.label === myMatch.games[3].result) });
    }

    loadSavedPlayers() {
        var myMatch = this.props.location.matchObj;
        if (myMatch.games !== null) {
            if (myMatch.games.length > 0) {
                this.setState({
                    g1p1: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[0].player1Id)),
                    g1p2: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[0].player2Id))
                });
            }
            if (myMatch.games.length > 1) {
                this.setState({
                    g2p1: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[1].player1Id)),
                    g2p2: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[1].player2Id))
                });
            }
            if (myMatch.games.length > 2) {
                this.setState({
                    g3p1: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[2].player1Id)),
                    g3p2: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[2].player2Id))
                });
            }
            if (myMatch.games.length > 3) {
                this.setState({
                    g4p1: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[3].player1Id)),
                    g4p2: this.state.playersList.find(obj => obj.value === parseInt(myMatch.games[3].player2Id))
                });
            }
        }
    }

    loadSavedTeams() {
        var myMatch = this.props.location.matchObj;
        this.setState({
            team1: this.state.teamsList.find(x => x.value === myMatch.team1Id),
            team2: this.state.teamsList.find(x => x.value === myMatch.team2Id)
        });
    }

    getPlayers() {
        fetch('api/Admin/Players')
            .then(res => res.json())
            .then(res => this.setState({ playersList: res }))
            .then(() => { this.loadSavedPlayers() })
            .catch(err => console.log("ERROR:", err));
    }

    getTeams() {
        fetch('api/Admin/TeamsForDropdown')
            .then(res => res.json())
            .then(res => this.setState({ teamsList: res }))
            .then(() => { this.loadSavedTeams() })
            .catch(err => console.log("ERROR:", err));
    }

    saveGames = newMatchId => {
        var byePlayer = 382;
        var myMatch = this.props.location.matchObj;

        var game1 = this.props.location.matchObj.games[0];
        if (game1.player1Id === null) game1.player1Id = byePlayer;
        if (game1.player2Id === null) game1.player2Id = byePlayer;

        console.log("Game 1 ID:", Number(game1.id));
        console.log("Game 1 Match ID:", newMatchId);
        console.log("Game 1:", game1);
        fetch('api/Admin/SaveGame/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                matchId: newMatchId,
                boardNum: 1,
                id: Number(game1.id),
                player1Id: game1.player1Id.toString(),
                player1Color: "W",
                player1Score: parseFloat(game1.player1Score),
                player1Team: parseInt(myMatch.team1Id),
                player2Id: game1.player2Id.toString(),
                player2Color: "B",
                player2Score: parseFloat(game1.player2Score),
                player2Team: parseInt(myMatch.team2Id),
                player1ExcludeFromScore: false,
                player2ExcludeFromScore: false
            })
        })
            .then(res => res.json())
            .then((res) => {
                var newid = res;
                this.props.location.matchObj.games[0].id = parseInt(newid);
            })
            .catch(err => console.log(err));

        var game2 = this.props.location.matchObj.games[1];
        if (game2.player1Id === null) game2.player1Id = byePlayer;
        if (game2.player2Id === null) game2.player2Id = byePlayer;
        //if (game2.player1Id !== null && game2.player2Id !== null) {
        fetch('api/Admin/SaveGame/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                matchId: newMatchId,
                boardNum: 2,
                id: game2.id,
                player1Id: game2.player1Id.toString(),
                player1Color: "B",
                player1Score: parseFloat(game2.player1Score),
                player1Team: myMatch.team1Id,
                player2Id: game2.player2Id.toString(),
                player2Color: "W",
                player2Score: parseFloat(game2.player2Score),
                player2Team: myMatch.team2Id,
                player1ExcludeFromScore: false,
                player2ExcludeFromScore: false
            })
        })
            .then(res => res.json())
            .then((data) => {
                var newid = data;
                this.props.location.matchObj.games[1].id = newid;
            })
            .catch(err => console.log(err));
        //}

        var game3 = this.props.location.matchObj.games[2];
        if (game3.player1Id === null) game3.player1Id = byePlayer;
        if (game3.player2Id === null) game3.player2Id = byePlayer;
        //if (game3.player1Id !== null && game3.player2Id !== null) {
        fetch('api/Admin/SaveGame/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                matchId: newMatchId,
                boardNum: 3,
                id: game3.id,
                player1Id: game3.player1Id.toString(),
                player1Color: "W",
                player1Score: parseFloat(game3.player1Score),
                player1Team: myMatch.team1Id,
                player2Id: game3.player2Id.toString(),
                player2Color: "B",
                player2Score: parseFloat(game3.player2Score),
                player2Team: myMatch.team2Id,
                player1ExcludeFromScore: false,
                player2ExcludeFromScore: false
            })
        })
            .then(res => res.json())
            .then((data) => {
                var newid = data;
                this.props.location.matchObj.games[2].id = newid;
            })
            .catch(err => console.log(err));
        //}

        var game4 = this.props.location.matchObj.games[3];
        if (game4.player1Id === null) game4.player1Id = byePlayer;
        if (game4.player2Id === null) game4.player2Id = byePlayer;
        //if (game4.player1Id !== null && game4.player2Id !== null) {
        fetch('api/Admin/SaveGame/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                matchId: newMatchId,
                boardNum: 4,
                id: game4.id,
                player1Id: game4.player1Id.toString(),
                player1Color: "B",
                player1Score: parseFloat(game4.player1Score),
                player1Team: myMatch.team1Id,
                player2Id: game4.player2Id.toString(),
                player2Color: "W",
                player2Score: parseFloat(game4.player2Score),
                player2Team: myMatch.team2Id,
                player1ExcludeFromScore: false,
                player2ExcludeFromScore: false
            })
        })
            .then(res => res.json())
            .then((data) => {
                var newid = data;
                this.props.location.matchObj.games[3].id = newid;
            })
            .catch(err => console.log(err));
        // }
    }



    submitEdit = e => {
        e.preventDefault();
        var myMatch = this.props.location.matchObj;
        console.log("save", myMatch);
        console.log("team 1 score", myMatch.team1Score);

        //Begin validation //
        if (isNaN(myMatch.team1Score)) {
            this.handleFailureAlert("Team 1 Score must be 0, 0.5, or 1")
            return;
        }
        if (myMatch.team1Score !== 1 && myMatch.team1Score !== 0 && myMatch.team1Score !== 0.5) {
            this.handleFailureAlert("Team 1 Score must be 0, 1, or 0.5")
            return;
        }
        if (isNaN(myMatch.team2Score)) {
            this.handleFailureAlert("Team 2 Score must be 0, 0.5, or 1")
            return;
        }
        if (myMatch.team2Score !== 1 && myMatch.team2Score !== 0 && myMatch.team2Score !== 0.5) {
            this.handleFailureAlert("Team 2 Score must be 0, 1, or 0.5")
            return;
        }
        if (isNaN(myMatch.thisRound)) {
            this.handleFailureAlert("Round number is required and must be a number")
            return;
        }
        if (myMatch.thisRound < 1) {
            this.handleFailureAlert("Round number must be greater than zero")
            return;
        }
        //End validation //

        if (myMatch.id === null || parseInt(myMatch.id) <= 0) {

            fetch('api/Admin/AddMatch/', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    matchDate: new Date(),
                    team1Id: myMatch.team1Id,
                    team2Id: myMatch.team2Id,
                    team1Score: myMatch.team1Score,
                    team2Score: myMatch.team2Score,
                    thisRound: myMatch.thisRound
                })
            })
                .then(res => res.json())
                .then((data) => {
                    console.log("dataid", data);
                    var newid = data;
                    myMatch.id = newid;
                    this.saveGames(newid);
                })
                .then(this.handleSuccessAlert())
                .catch(err => console.log(err));

        } else {

            fetch('api/Admin/SaveMatch/', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: myMatch.id,
                    team1Id: myMatch.team1Id,
                    team2Id: myMatch.team2Id,
                    team1Score: myMatch.team1Score,
                    team2Score: myMatch.team2Score,
                    thisRound: myMatch.thisRound,
                    season: myMatch.season
                })
            })
                .then(res => res.json())
                .then((data) => {
                    var newid = data;
                    this.saveGames(newid);
                })
                .then(this.handleSuccessAlert())
                .catch(err => console.log(err));
        }

        fetch('api/Admin/UpdateTiebreakPts', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: myMatch.id,
                team1Id: myMatch.team1Id,
                team2Id: myMatch.team2Id,
                team1Score: myMatch.team1Score,
                team2Score: myMatch.team2Score,
                thisRound: myMatch.thisRound,
                season: myMatch.season
            })
        });
    }

    handleDelete(id) {
        //var myMatch = this.state.matchObj;
        //alert("Are you sure you want to delete: " + id);
    }

    handleSuccessAlert() {
        this.setState({
            alertVisible: true,
            alertColor: "success",
            alertText: "Your changes have been successfully saved"
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 3000);
    }

    handleFailureAlert(textMsg) {
        this.setState({
            alertVisible: true,
            alertColor: "danger",
            alertText: textMsg
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 5000);
    }

    handleTeamChange = (e, action) => {
        if (e !== null) {
            //console.log("changeteam: ", e.value + " (" + e.label + ")");
            var newMatchObj = this.props.location.matchObj;
            var curTeamId = e.value;
            var curTeamName = e.label;

            switch (action.name) {
                case "team1Name":
                    newMatchObj.team1Id = curTeamId;
                    newMatchObj.team1Name = curTeamName;
                    this.setState({ team1: this.state.teamsList.find(x => x.value === curTeamId) });
                    break;
                case "team2Name":
                    newMatchObj.team2Id = curTeamId;
                    newMatchObj.team2Name = curTeamName;
                    this.setState({ team2: this.state.teamsList.find(x => x.value === curTeamId) });
                    break;

                case "team3Name":
                    newMatchObj.team3Id = curTeamId;
                    newMatchObj.team3Name = curTeamName;
                    this.setState({ team3: this.state.teamsList.find(x => x.value === curTeamId) });
                    break;
                case "team4Name":
                    newMatchObj.team4Id = curTeamId;
                    newMatchObj.team4Name = curTeamName;
                    this.setState({ team4: this.state.teamsList.find(x => x.value === curTeamId) });
                    break;
                default:
                    break;
            }
            this.props.location.matchObj = newMatchObj;
        }
    }

    handlePlayerChange = (e, action) => {
        if (e !== null) {
            //console.log(e.value + " (" + e.label + ")");
            var newPlayer = e.value
            switch (action.name) {
                case "game1player1":
                    this.props.location.matchObj.games[0].player1Id = newPlayer;
                    this.setState({ g1p1: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game1player2":
                    this.props.location.matchObj.games[0].player2Id = e.value;
                    this.setState({ g1p2: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game2player1":
                    this.props.location.matchObj.games[1].player1Id = e.value;
                    this.setState({ g2p1: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game2player2":
                    this.props.location.matchObj.games[1].player2Id = e.value;
                    this.setState({ g2p2: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game3player1":
                    this.props.location.matchObj.games[2].player1Id = e.value;
                    this.setState({ g3p1: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game3player2":
                    this.props.location.matchObj.games[2].player2Id = e.value;
                    this.setState({ g3p2: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game4player1":
                    this.props.location.matchObj.games[3].player1Id = e.value;
                    this.setState({ g4p1: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                case "game4player2":
                    this.props.location.matchObj.games[3].player2Id = e.value;
                    this.setState({ g4p2: this.state.playersList.find(obj => obj.value === parseInt(newPlayer)) });
                    break;
                default:
                    break;
            }
        }
    }

    handleResultChange = (e, action) => {
        if (e !== null) {
            //console.log(e.value + " (" + e.label + ")");
            var thisResult = e.value;
            var valWin = "1 - 0";
            var valLoss = "0 - 1";
            var valDraw = "0.5 - 0.5";
            //var valDoubleWin = "1 - 1";
            var valResult = "";

            var gameNum = 0;
            switch (action.name) {
                case "game1result":
                    gameNum = 0;
                    break;
                case "game2result":
                    gameNum = 1;
                    break;
                case "game3result":
                    gameNum = 2;
                    break;
                case "game4result":
                    gameNum = 3;
                    break;
                default:
                    break;
            }

            if (thisResult === "W") {
                valResult = valWin;
                this.props.location.matchObj.games[gameNum].player1Score = 1;
                this.props.location.matchObj.games[gameNum].player2Score = 0;
            } else if (thisResult === "L") {
                valResult = valLoss;
                this.props.location.matchObj.games[gameNum].player1Score = 0;
                this.props.location.matchObj.games[gameNum].player2Score = 1;
            } else if (thisResult === "W2") {
                valResult = valLoss;
                this.props.location.matchObj.games[gameNum].player1Score = 1;
                this.props.location.matchObj.games[gameNum].player2Score = 1;
            } else {
                valResult = valDraw
                this.props.location.matchObj.games[gameNum].player1Score = 0.5;
                this.props.location.matchObj.games[gameNum].player2Score = 0.5;
            }

            this.props.location.matchObj.games[gameNum].result = valResult;

            switch (action.name) {
                case "game1result":
                    this.setState({ g1r: resultOptions.find(obj => obj.value === valResult) });
                    break;
                case "game2result":
                    this.setState({ g2r: resultOptions.find(obj => obj.value === valResult) });
                    break;
                case "game3result":
                    this.setState({ g3r: resultOptions.find(obj => obj.value === valResult) });
                    break;
                case "game4result":
                    this.setState({ g4r: resultOptions.find(obj => obj.value === valResult) });
                    break;
                default:
                    break;
            }
        }

    }

    onChange = e => {
        e.preventDefault();
        //console.log("changefield", e.target.name);

        if (e !== null) {
            var newValue = e.target.value;

            var newMatchObj = this.props.location.matchObj;
            switch (e.target.name) {
                case "team1Score":
                    newMatchObj.team1Score = parseFloat(newValue);
                    break;
                case "team2Score":
                    newMatchObj.team2Score = parseFloat(newValue);
                    break;
                case "thisRound":
                    newMatchObj.thisRound = parseInt(newValue);
                    break;
                default:
                    break;
            }
            this.props.location.matchObj = newMatchObj;
            //this.setState({ matchObj: newMatchObj });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect
                to="/Admin"
            />;
        }

        var myMatch = this.props.location.matchObj;
        var isNewMode = this.props.location.isNew;

        return (
            <center>
                <Helmet>
                    <title>MHSCL Admin - Edit Match</title>
                </Helmet>

                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
                    {this.state.alertText}
                </Alert>

                {isNewMode ? <h1>Create Match</h1> : <h1>Edit Match (#{myMatch.id})</h1>}

                <Form onSubmit={this.submitEdit}>
                    <div>
                        <table className="edit-wholetable">
                            <tbody>
                                <tr>
                                    <td className="td-font">Round Number</td>
                                    <td>
                                        <Input name="thisRound" type="text" className="edit-val" required
                                            onChange={this.onChange} placeholder="Round #..."
                                            defaultValue={myMatch === null ? '' : myMatch.thisRound} />
                                    </td>
                                    <td colSpan='2'></td>
                                </tr>
                                <tr>
                                    <td className="td-font">Team 1 (White)</td>
                                    <td>
                                        <Select
                                            onChange={this.handleTeamChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="team1Name"
                                            options={this.state.teamsList}
                                            value={this.state.team1}
                                            styles={customStyles}
                                        />
                                    </td>
                                    <td className="td-font">Team 2 (Black)</td>
                                    <td>
                                        <Select
                                            onChange={this.handleTeamChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="team2Name"
                                            options={this.state.teamsList}
                                            value={this.state.team2}
                                            styles={customStyles}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font">Team 1 Score</td>
                                    <td>
                                        <Input name="team1Score" type="text" className="edit-val" required
                                            onChange={this.onChange} placeholder="Score..."
                                            defaultValue={myMatch === null ? '' : myMatch.team1Score} />
                                    </td>
                                    <td className="td-font">Team 2 Score</td>
                                    <td>
                                        <Input name="team2Score" type="text" className="edit-val" required
                                            onChange={this.onChange} placeholder="Score..."
                                            defaultValue={myMatch === null ? '' : myMatch.team2Score} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <table className="edit-wholetable">
                            <tbody>
                                <tr>
                                    <th className="edit-th">Bd</th>
                                    <th className="edit-th">{myMatch.team1Name}</th>
                                    <th className="edit-th">Result</th>
                                    <th className="edit-th">{myMatch.team2Name}</th>
                                </tr>
                                <tr>
                                    <td className="edit-table td-font">1</td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game1player1"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g1p1}
                                        /></td>
                                    <td className="edit-table col-width-sm">
                                        <Select
                                            name="game1result"
                                            onChange={this.handleResultChange}
                                            options={resultOptions}
                                            value={this.state.g1r}
                                        /></td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game1player2"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g1p2}
                                        /></td>
                                </tr>
                                <tr>
                                    <td className="edit-table td-font">2</td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game2player1"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g2p1}
                                        /></td>
                                    <td className="edit-table col-width-sm">
                                        <Select
                                            name="game2result"
                                            onChange={this.handleResultChange}
                                            options={resultOptions}
                                            value={this.state.g2r}
                                        /></td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game2player2"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g2p2}
                                        /></td>
                                </tr>
                                <tr>
                                    <td className="edit-table td-font">3</td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game3player1"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g3p1}
                                        /></td>
                                    <td className="edit-table col-width-sm">
                                        <Select
                                            name="game3result"
                                            onChange={this.handleResultChange}
                                            options={resultOptions}
                                            value={this.state.g3r}
                                        /></td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game3player2"
                                            styles={customStyles}
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g3p2}
                                        /></td>
                                </tr>
                                <tr>
                                    <td className="edit-table td-font">4</td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game4player1"
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g4p1}
                                        /></td>
                                    <td className="edit-table col-width-sm">
                                        <Select
                                            name="game4result"
                                            onChange={this.handleResultChange}
                                            options={resultOptions}
                                            value={this.state.g4r}
                                        /></td>
                                    <td className="edit-table col-width-med">
                                        <Select
                                            name="game4player2"
                                            onChange={this.handlePlayerChange}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={this.state.playersList}
                                            value={this.state.g4p2}
                                        /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="center-div" style={{ marginTop: '25px' }}>
                        <Button color="success" type="submit">Save</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="info" onClick={this.goBack}>Close</Button>
                    </div>
                </Form>

                {/*<div style={{ marginTop: '50px' }}>*/}
                {/*    <Button color="danger" onClick={this.handleDelete(this.props.location.matchObj.id)}>Delete</Button>*/}
                {/*</div>*/}

            </center>
        );
    }
}
