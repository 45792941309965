import React, { Component } from 'react';
import _ from 'lodash'
import { Table, Icon, Dimmer, Loader } from 'semantic-ui-react'
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'

export class Player extends Component {
    state = {
        player: null,
        playerid: '',
        active: true
    }

    componentDidMount() {
        var id = this.props.match.params.playerId;
        this.setState({ playerid: id });

        this.logPageHit(id);

        fetch('api/Player/' + id)
            .then(res => res.json())
            .then(res => this.setState({ player: res }))
            .then(this.setState({ active: false }))
            .catch(err => console.log("ERROR:", err));
    }

    logPageHit(id) {
        var pagename = 'Player-' + id;
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        const { player, playerid } = this.state

        return (
            <div style={{ textAlign: 'center' }}>
                <Helmet>
                    <title>Individual Statistics from the Minnesota High School Chess League</title>
                </Helmet>
                {player != null &&
                    <div>
                        <h1> Individual Stats for {player.playerName} ({player.seasonDisplay}) </h1>
                        <div style={{ fontSize: '1.3em' }}>
                            <div className="p-2">
                                <span className="font-weight-bold">Team: </span><span className="colored-text">{player.teamName}</span>
                            </div>
                            <div className="p-2">
                                <span className="font-weight-bold">Lichess: </span>
                                <span className="colored-text">
                                    <a href={player.lichessUrl} target="_blank" rel="noopener noreferrer">{player.lichessUsername}</a>
                                </span>
                            </div>
                        </div>

                        <Dimmer active={this.state.active}>
                            <Loader>Loading</Loader>
                        </Dimmer>

                        <Table collapsing celled unstackable className="purple" style={{ display: 'inline-table' }} >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Round</Table.HeaderCell>
                                    <Table.HeaderCell>Board</Table.HeaderCell>
                                    <Table.HeaderCell>Player 1 (White)</Table.HeaderCell>
                                    <Table.HeaderCell>Result</Table.HeaderCell>
                                    <Table.HeaderCell>Player 2 (Black)</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {_.map(player.games, ({ id, boardNum, thisRound, player1Name, player1TeamName, player1Id, result, player2Name, player2TeamName, player2Id, player1Score, player2Score }) => (
                                    <Table.Row key={id}>

                                        <Table.Cell className="text-center">{thisRound}</Table.Cell>
                                        <Table.Cell className="text-center">{boardNum}</Table.Cell>

                                        {(player1Score === 1 && player1Id === playerid) ?
                                            (
                                                <Table.Cell positive><Icon name='checkmark' />{player1Name}</Table.Cell>
                                            ) :

                                            (player1Score === 0 && player1Id === playerid) ?
                                                (
                                                    <Table.Cell negative>{player1Name}</Table.Cell>
                                                ) : (
                                                    <Table.Cell>{player1Name}
                                                        <span className="team-text"> ({player1TeamName})</span>
                                                    </Table.Cell>
                                                )}

                                        <Table.Cell className="text-center">{result}</Table.Cell>

                                        {(player2Score === 1 && player2Id === playerid) ?
                                            (
                                                <Table.Cell positive><Icon name='checkmark' />{player2Name}</Table.Cell>
                                            )
                                            :
                                            (player2Score === 0 && player2Id === playerid) ?
                                                (
                                                    <Table.Cell negative>{player2Name}</Table.Cell>
                                                ) : (
                                                    <Table.Cell>{player2Name}
                                                        <span className="team-text"> ({player2TeamName})</span>
                                                    </Table.Cell>
                                                )}

                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        <br /><br />

                        {player.historicalGames != null &&
                            <div>
                                <h2>Historical Games</h2>

                                <Table collapsing celled unstackable className="purple" style={{ display: 'inline-table' }} >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Season</Table.HeaderCell>
                                            <Table.HeaderCell>Round</Table.HeaderCell>
                                            <Table.HeaderCell>Board</Table.HeaderCell>
                                            <Table.HeaderCell>Player 1 (White)</Table.HeaderCell>
                                            <Table.HeaderCell>Result</Table.HeaderCell>
                                            <Table.HeaderCell>Player 2 (Black)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {_.map(player.historicalGames, ({ id, season, boardNum, thisRound, player1Name, player1TeamName, player1Id, result, player2Name, player2TeamName, player2Id, player1Score, player2Score }) => (
                                            <Table.Row key={id}>

                                                <Table.Cell className="text-center">{season}</Table.Cell>
                                                <Table.Cell className="text-center">{thisRound}</Table.Cell>
                                                <Table.Cell className="text-center">{boardNum}</Table.Cell>

                                                {(player1Score === 1 && player1Id === playerid) ?
                                                    (
                                                        <Table.Cell positive><Icon name='checkmark' />{player1Name}</Table.Cell>
                                                    ) :

                                                    (player1Score === 0 && player1Id === playerid) ?
                                                        (
                                                            <Table.Cell negative>{player1Name}</Table.Cell>
                                                        ) : (
                                                            <Table.Cell>{player1Name}
                                                                <span className="team-text"> ({player1TeamName})</span>
                                                            </Table.Cell>
                                                        )}

                                                <Table.Cell className="text-center">{result}</Table.Cell>

                                                {(player2Score === 1 && player2Id === playerid) ?
                                                    (
                                                        <Table.Cell positive><Icon name='checkmark' />{player2Name}</Table.Cell>
                                                    )
                                                    :
                                                    (player2Score === 0 && player2Id === playerid) ?
                                                        (
                                                            <Table.Cell negative>{player2Name}</Table.Cell>
                                                        ) : (
                                                            <Table.Cell>{player2Name}
                                                                <span className="team-text"> ({player2TeamName})</span>
                                                            </Table.Cell>
                                                        )}

                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        }
                    </div>

                }
                <div style={{ marginBottom: '50px' }} />
<br/><br/>
            </div >
        );
    }
}
