import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { GOOGLE_ANALYTICS_API, customStyles } from './constants';
import { Form, Button, Input, Alert, Label } from 'reactstrap';
import Select from 'react-select'
import '../styles/editpage.css';


export class EditPlayer extends Component {
    state = {
        redirect: false,
        alertVisible: false,
        alertText: '',
        alertColor: '',
        teamsList: [],
        team1: null,
        exclude: false
    }

    componentWillMount() {
        if (localStorage.getItem("mhscl-user") === null) {
            this.setState({ redirect: true });
        }
    }

    goBack = () => {
        this.props.history.push('/Players')
    }

    componentDidMount() {
        this.logPageHit();
        //console.log("Loading", this.props.location.playerObj);
        this.getTeams();
    }

    handleFailureAlert(textMsg) {
        this.setState({
            alertVisible: true,
            alertColor: "danger",
            alertText: textMsg
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 5000);
    }

    handleSuccessAlert() {
        this.setState({
            alertVisible: true,
            alertColor: "success",
            alertText: "Your changes have been successfully saved"
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 3000);
    }

    logPageHit() {
        var pagename = 'EditPlayer';
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);

        var url = 'api/Admin/LogPageHit/' + pagename + '/' + localStorage.getItem('mhscl-user');
        fetch(url, { method: 'post' });
    }

    getTeams() {
        fetch('api/Admin/TeamsForDropdown')
            .then(res => res.json())
            .then(res => this.setState({ teamsList: res }))
            .then(() => { this.loadPlayerObj() })
            .catch(err => console.log("ERROR:", err));
    }

    loadPlayerObj() {
        var myPlayer = this.props.location.playerObj;
        this.setState({
            team1: this.state.teamsList.find(x => x.value === myPlayer.teamId),
            exclude: myPlayer.excludeFromStandings
        });
    }

    validateData() {
        var myPlayer = this.props.location.playerObj;
        console.log("Validating");
        if (isNaN(myPlayer.rating)) {
            console.log("Warning -- rating not a number");
            this.handleFailureAlert("Integer is required for the Rating field");
            return false;
        }
        return true;
    }

    savePlayer() {
        var myPlayer = this.props.location.playerObj;
        console.log("Save: ", myPlayer);

        fetch('api/Admin/SavePlayer/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: parseInt(myPlayer.id),
                rating: Number(myPlayer.rating),
                firstName: myPlayer.firstName,
                lastName: myPlayer.lastName,
                lichessUsername: myPlayer.lichessUsername,
                excludeFromStandings: myPlayer.excludeFromStandings,
                teamId: myPlayer.teamId
            })
        })
            .then(res => res.json())
            .then((res) => {
                var newid = res;
                this.props.location.playerObj.id = parseInt(newid);
            })
            .then(this.handleSuccessAlert())
            .catch(err => console.log(err));
    }

    submitEdit = e => {
        e.preventDefault();
        if (!this.validateData())
            return;
        this.savePlayer();
    }

    handleChange = e => {
        if (e !== null) {
            console.log(e.target.value + " (" + e.target.name + ")");
            var newValue = e.target.value;
            switch (e.target.name) {
                case "firstName":
                    this.props.location.playerObj.firstName = newValue;
                    break;
                case "lastName":
                    this.props.location.playerObj.lastName = newValue;
                    break;
                case "rating":
                    this.props.location.playerObj.rating = newValue;
                    break;
                case "username":
                    this.props.location.playerObj.lichessUsername = newValue;
                    break;
                case "exclude":
                    this.props.location.playerObj.excludeFromStandings = e.target.checked;
                    this.setState({ exclude: e.target.checked });

                    break;
                default:
                    break;
            }
        }
    }


    handleTeamChange = (e, action) => {
        if (e !== null) {
            var newObj = this.props.location.playerObj;

            var curTeamId = e.value;
            newObj.teamId = curTeamId;
            this.setState({ team1: this.state.teamsList.find(x => x.value === curTeamId) });

            this.props.location.playerObj = newObj;
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect
                to="/Admin"
            />;
        }

        var myPlayer = this.props.location.playerObj;
        var title = "Add New Player";
        if (Number(myPlayer.id) > 0)
            title = "Edit Player (" + myPlayer.playerName + ")";

        return (
            <div style={{ textAlign: 'center' }} >
                <Helmet>
                    <title>MHSCL Admin - Edit Player</title>
                </Helmet>

                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
                    {this.state.alertText}
                </Alert>

                <h1>{title}</h1>

                <Form onSubmit={this.submitEdit}>

                    <div style={{ marginTop: '50px' }}>
                        <table className="edit-wholetable">
                            <tbody>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>First Name</Label>
                                    </td>
                                    <td className="td-font td-input-sm">
                                        <Input
                                            name="firstName"
                                            defaultValue={myPlayer === null ? '' : myPlayer.firstName}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Last Name</Label>
                                    </td>
                                    <td className="td-font td-input-sm">
                                        <Input
                                            name="lastName"
                                            defaultValue={myPlayer === null ? '' : myPlayer.lastName}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Rating</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="rating"
                                            defaultValue={myPlayer === null ? '' : myPlayer.rating}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Lichess Username</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="username"
                                            defaultValue={myPlayer === null ? '' : myPlayer.lichessUsername}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Team</Label>
                                    </td>
                                    <td className="td-font">
                                        <Select
                                            onChange={this.handleTeamChange}
                                            styles={customStyles}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="teamName"
                                            options={this.state.teamsList}
                                            value={this.state.team1}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Exclude From Standings</Label>
                                    </td>
                                    <td className="edit-table td-font td-checkbox" style={{textAlign:'left', paddingLeft:'25px'}}>
                                        <Input
                                            name="exclude"
                                            type="checkbox"
                                            className="edit-checkbox"
                                            checked={this.state.exclude}
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="center-div" style={{ marginTop: '25px' }}>
                        <Button color="success" type="submit">Save</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="info" onClick={this.goBack}>Close</Button>
                    </div>
                </Form>
            </div >
        );
    }
}
