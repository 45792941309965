import _ from 'lodash'
import React, { Component } from 'react';
import { Table, Dimmer, Loader } from 'semantic-ui-react'
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap';

export class Players extends Component {
    state = {
        column: null,
        data: [],
        direction: null,
        active: true,
        newPlayer: null,
        admin: false,
        seasonTitle: ''
    }

    componentDidMount() {
        this.logPageHit();
        this.getBlankPlayer();
        this.getPlayers();
        this.getSettings();

        if (localStorage.getItem("mhscl-user") !== null) {
            this.setState({ admin: true });
        }
    }

    getPlayers() {
        fetch('api/Players')
            .then(res => res.json())
            .then(res => this.setState({ data: res }))
            .then(this.setState({ active: false }))
            .catch(err => console.log("ERROR:", err));
    }

    getBlankPlayer() {
        fetch('api/Player/BlankPlayer/')
            .then(res => res.json())
            .then(res => this.setState({ newPlayer: res }))
            .catch(err => console.log("ERROR:", err))
    }

    getSettings() {
        fetch('api/Admin/Setting/1')
            .then(res => res.json())
            .then(res => this.setState({ seasonTitle: res }))
            .catch(err => console.log("ERROR:", err));
    }

    logPageHit() {
        var pagename = 'Players';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            })

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }


    render() {
        const { column, data, direction, seasonTitle } = this.state

        return (
            <div>
                <Helmet>
                    <title>Listing of players signed up for the {seasonTitle} Minnesota High School Chess League</title>
                </Helmet>
                <h1>List of Players</h1>

                {this.state.admin &&
                    <div>
                    <Link to={{
                        pathname: '/EditPlayer',
                        playerObj: this.state.newPlayer
                    }}>
                        <Button
                            className="btn-sm"
                            color="primary">
                            Add New
                        </Button>
                    </Link>
                    </div>
                }
                <Dimmer active={this.state.active}>
                    <Loader>Loading</Loader>
                </Dimmer>

                <Table sortable celled fixed striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                className="tableHdr"
                                sorted={column === 'playerName' ? direction : null}
                                onClick={this.handleSort('playerName')}
                            >
                                Full Name
            </Table.HeaderCell>
                            <Table.HeaderCell
                                className="tableHdr"
                                sorted={column === 'teamName' ? direction : null}
                                onClick={this.handleSort('teamName')}
                            >
                                Team
            </Table.HeaderCell>
                            <Table.HeaderCell
                                className="tableHdr"
                                sorted={column === 'ratingDisplay' ? direction : null}
                                onClick={this.handleSort('ratingDisplay')}
                            >
                                Rating
            </Table.HeaderCell>
                            <Table.HeaderCell
                                className="tableHdr"
                                sorted={column === 'lichessUsername' ? direction : null}
                                onClick={this.handleSort('lichessUsername')}
                            >
                                Lichess Username
                                </Table.HeaderCell>

                            {this.state.admin &&
                                <Table.HeaderCell style={{ width: '65px' }}></Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        {data.map(player => (
                            <Table.Row key={player.id}>
                                <Table.Cell>
                                    <Link to={`/player/${player.id}`}>{player.playerName}</Link>
                                </Table.Cell>
                                <Table.Cell>
                                    {player.teamName}
                                </Table.Cell>
                                <Table.Cell>
                                    {player.ratingDisplay}
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={player.lichessUrl} target="_blank" rel="noopener noreferrer">{player.lichessUsername}</a>
                                </Table.Cell>
                                {this.state.admin &&
                                    <Table.Cell>
                                        <span>
                                            <Link to={{
                                                pathname: '/EditPlayer',
                                                playerObj: player
                                            }}>
                                                <Button
                                                    className="btn-sm"
                                                    color="primary">
                                                    Edit
                                                </Button>
                                            </Link>
                                        </span>
                                    </Table.Cell>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
