import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { GOOGLE_ANALYTICS_API } from './constants';
import { Form, Button, Input, Alert, Label } from 'reactstrap';
import '../styles/editpage.css';


export class EditTeam extends Component {
    state = {
        redirect: false,
        alertVisible: false,
        alertText: '',
        alertColor: ''
    }

    componentWillMount() {
        if (localStorage.getItem("mhscl-user") === null) {
            this.setState({ redirect: true });
        }
    }

    componentDidMount() {
        this.logPageHit();
    }

    goBack = () => {
        this.props.history.push('/ViewTeams')
    }


    handleFailureAlert(textMsg) {
        this.setState({
            alertVisible: true,
            alertColor: "danger",
            alertText: textMsg
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 5000);
    }

    handleSuccessAlert() {
        this.setState({
            alertVisible: true,
            alertColor: "success",
            alertText: "Your changes have been successfully saved"
        });

        setTimeout(() => {
            this.setState({
                alertVisible: false
            });
        }, 3000);
    }

    logPageHit() {
        var pagename = 'EditTeam';
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);

        var url = 'api/Admin/LogPageHit/' + pagename + '/' + localStorage.getItem('mhscl-user');
        fetch(url, { method: 'post' });
    }

    validateData() {
        var myTeam = this.props.location.teamObj;
        console.log("Validating");
        if (isNaN(myTeam.avgRating)) {
            console.log("Warning -- avg rating not a number");
            this.handleFailureAlert("Integer is required for the Avg Rating field");
            return false;
        }
        return true;
    }

    saveTeam() {
        var myTeam = this.props.location.teamObj;
        console.log("Save: ", myTeam);

        fetch('api/Admin/SaveTeam/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: parseInt(myTeam.id),
                teamName: myTeam.teamName,
                avgRating: parseInt(myTeam.avgRating),
                season: parseInt(myTeam.season),
                points: parseFloat(myTeam.points),
                color: myTeam.color,
                playoffTier: parseInt(myTeam.playoffTier)
            })
        })
            .then(res => res.json())
            .then((res) => {
                var newid = res;
                this.props.location.teamObj.id = parseInt(newid);
            })
            .then(this.handleSuccessAlert())
            .catch(err => console.log(err));
    }

    submitEdit = e => {
        e.preventDefault();
        if (!this.validateData())
            return;
        this.saveTeam();
    }

    handleChange = e => {
        if (e !== null) {
            console.log(e.target.value + " (" + e.target.name + ")");
            var newValue = e.target.value;
            switch (e.target.name) {
                case "teamName":
                    this.props.location.teamObj.teamName = newValue;
                    break;
                case "avgRating":
                    this.props.location.teamObj.avgRating = newValue;
                    break;
                case "season":
                    this.props.location.teamObj.season = newValue;
                    break;
                case "points":
                    this.props.location.teamObj.points = newValue;
                    break;
                case "playoffTier":
                    this.props.location.teamObj.playoffTier = newValue;
                    break;
                case "color":
                    this.props.location.teamObj.color = newValue;
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect
                to="/Admin"
            />;
        }

        var myTeam = this.props.location.teamObj;
        var title = "Add New Team";
        if (Number(myTeam.id) > 0)
            title = "Edit Team (" + myTeam.teamName + ")";

        return (
            <div style={{ textAlign: 'center' }} >
                <Helmet>
                    <title>MHSCL Admin - Edit Team</title>
                </Helmet>

                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
                    {this.state.alertText}
                </Alert>

                <h1>{title}</h1>

                <Form onSubmit={this.submitEdit}>

                    <div style={{ marginTop: '50px' }}>
                        <table className="edit-wholetable-sm">
                            <tbody>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Team Name</Label>
                                    </td>
                                    <td className="td-font td-input-sm">
                                        <Input
                                            name="teamName"
                                            defaultValue={myTeam === null ? '' : myTeam.teamName}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Avg Rating</Label>
                                    </td>
                                    <td className="td-font td-input-sm">
                                        <Input
                                            name="avgRating"
                                            defaultValue={myTeam === null ? '' : myTeam.avgRating}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Season</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="season"
                                            defaultValue={myTeam === null ? '' : myTeam.season}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Points</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="points"
                                            defaultValue={myTeam === null ? '' : myTeam.points}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Playoff Tier No.</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="playoffTier"
                                            defaultValue={myTeam === null ? '' : myTeam.playoffTier}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Playoff Tier Color</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            name="color"
                                            defaultValue={myTeam === null ? '' : myTeam.color}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-font td-label">
                                        <Label>Exclude From Standings</Label>
                                    </td>
                                    <td className="td-font">
                                        <Input
                                            readOnly
                                            name="excludeFromStandings"
                                            defaultValue={myTeam === null ? '' : myTeam.excludeFromStandings}
                                            onChange={this.handleChange}
                                            className="edit-val"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="center-div" style={{ marginTop: '25px' }}>
                        <Button color="success" type="submit">Save</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="info" onClick={this.goBack}>Close</Button>
                    </div>
                </Form>
            </div >
        );
    }
}
