import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export class Home extends Component {
    state = {
        seasonTitle: ''
    }

    static displayName = Home.name;

    componentDidMount() {
        this.logPageHit();
        this.getSettings();
    }

    logPageHit() {
        var pagename = 'Home';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getSettings() {
        fetch('api/Admin/Setting/1')
            .then(res => res.json())
            .then(res => this.setState({ seasonTitle: res }))
            .catch(err => console.log("ERROR:", err));
    }

    render() {
        return (
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <Helmet>
                    <title>Minnesota High School Chess League {this.state.seasonTitle}</title>
                </Helmet>
                <div style={{ textAlign: 'center' }}>
                    <h1>Minnesota High School Chess League</h1>
                    <h2>{this.state.seasonTitle} Season</h2>
                </div>
                <br />

                <div className="jumbotron" style={{ padding: '20px' }}>
                    <div className="jumbotron-text"><Link to={`/Standings/`}>Standings</Link> - View the points accumulated by each team and player</div>
                    <div className="jumbotron-text"><Link to={`/Games/`}>Results</Link> - Shows the result from each match and game played</div>
                    <div className="jumbotron-text"><Link to={`/Players/`}>Players</Link> - A listing of all the participants</div>
                    <div className="jumbotron-text"><Link to={`/Rules/`}>Rules</Link> - Complete list of rules for this league</div>
                    <div className="jumbotron-text"><Link to={`/Instructions/`}>Instructions</Link> - Instructions for the players</div>
                    <div className="jumbotron-text"><Link to={`/About/`}>About</Link> - Brief description of this league</div>
                </div>
                <br />

                <div style={{ textAlign: 'center' }}>
                    <h2>2023-24 Final Results</h2>
                </div>
                <br/>
                <div className="jumbotron" style={{ padding: '20px', fontFamily: 'consolas', marginLeft:'70px', marginRight: '70px' }}>
                    <div style={{ fontSize: '16px', color: 'black', fontWeight: '600' }}>Team Awards:</div>

                    <span style={{ color: 'brown' }}>1st Overall - </span>Eastview 1
                    <br />
                    <span style={{ color: 'brown' }}>2nd Overall - </span>Eastview 2
                    <br />
                    <span style={{ color: 'brown' }}>3rd Overall - </span>RASCL All League Team
                    <br />
                    <span style={{ color: 'brown' }}>1st U1300 - </span>Nicollet MS
                    <br />
                    <span style={{ color: 'brown' }}>1st U1000/Unrated - </span>White Bear Lake 1
                    <br />
                    <span style={{ color: 'brown' }}>2nd U1000/Unrated - </span>Wayzata 3

                    <br />
                    <br />
                    <div style={{ fontSize: '16px', color: 'black', fontWeight: '600' }}>Individual Awards:</div>
                    <br/>
                    <div style={{ fontSize: '14px', color: 'indianred', fontWeight: '600' }}>Board 1:</div>
                    <span style={{ color: 'brown' }}>1st - </span>Yusuf Khan, Eden Prairie
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Kartik Saravanapandi, Eastview
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Martijn Krook-Magnuson, Roseville

                    <br />
                    <br />
                    <div style={{ fontSize: '14px', color: 'indianred', fontWeight: '600' }}>Board 2:</div>
                    <span style={{ color: 'brown' }}>1st - </span>Jayan Jayachandran, Century
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Bami Olofinboba, Eden Prairie
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Jeremy Prince, St. Paul Central

                    <br />
                    <br />
                    <div style={{ fontSize: '14px', color: 'indianred', fontWeight: '600' }}>Board 3:</div>
                    <span style={{ color: 'brown' }}>1st - </span>Karn Kaura, Blake
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Anvith Pothula, Eastview
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Jackson McGregor, Eastview
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Ruveen Perera, Eastview

                    <br />
                    <br />
                    <div style={{ fontSize: '14px', color: 'indianred', fontWeight: '600' }}>Board 4:</div>
                    <span style={{ color: 'brown' }}>1st - </span>Carson Score, Wayzata
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Karthi Sella, Eastview
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Evan Nguyen, Eastview

                    <br />
                    <br />
                    <div style={{ fontSize: '14px', color: 'indianred', fontWeight: '600' }}>Alternates:</div>
                    <span style={{ color: 'brown' }}>1st - </span>Noah Corbesia, White Bear Lake 
                    <br />
                    <span style={{ color: 'brown' }}>2nd - </span>Tsering Dhundup, Eden Prairie
                    <br />
                    <span style={{ color: 'brown' }}>3rd - </span>Peter Pham, Eastview
                    <br />
                    <span style={{ color: 'brown' }}>3rd - </span>Ian Lenzen, Wayzata
                    <br />
                    <span style={{ color: 'brown' }}>3rd - </span>Frederic Abrahams, Eden Prairie
                    <br />
                    <span style={{ color: 'brown' }}>3rd - </span>Vardaan Sharma, Wayzata
                    <br />
                    <br />
                </div>
                <br/><br/>
            </div>
        );
    }
}
