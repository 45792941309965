import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'


export class Schedule extends Component {

    componentDidMount() {
        this.logPageHit();
    }

    logPageHit() {
        var pagename = 'Schedule';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        return (
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <Helmet>
                    <title>Schedule for the Minnesota High School Chess League</title>
                </Helmet>
                <h1>Season Schedule</h1>

                <div>
                    <p className="blue-text">
                    Coming Soon...
                        
                    </p>
                </div>
            </div>
        );
    }
}
