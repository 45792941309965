import React, { Component } from 'react';
import _ from 'lodash'
import { Table, Button, Dimmer, Loader } from 'semantic-ui-react'
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export class Standings extends Component {
    state = {
        column: null,
        data: [],
        direction: null,
        selectedBtn: 0,
        active: true
    }

    componentDidMount() {
        this.logPageHit();
        this.fetchData(this.state.selectedBtn);
    }

    logPageHit() {
        var pagename = 'Standings';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    fetchData(id) {
        this.setState({ data: [] });

        if (id === 0) {
            fetch('api/TeamStandings/')
                .then(res => res.json())
                .then(res => this.setState({ data: res }))
                .then(this.setState({ active: false }))
                .catch(err => console.log("ERROR:", err));
        } else if (id === 100) {
            fetch('api/PlayoffStandings/')
                .then(res => res.json())
                .then(res => this.setState({ data: res }))
                .then(this.setState({ active: false }))
                .catch(err => console.log("ERROR:", err));
        } else {
            fetch('api/BoardStandings/' + id)
                .then(res => res.json())
                .then(res => this.setState({ data: res }))
                .then(this.setState({ active: false }))
                .catch(err => console.log("ERROR:", err));
        }
    }

    handleSort = (clickedColumn) => () => {
        const { column, data, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending'
            });

            return;
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });
    }


    handleButtonClick(btnId) {
        this.setState({
            selectedBtn: btnId
        });

        this.fetchData(btnId);
    }

    render() {
        const { column, data, direction, selectedBtn, active } = this.state

        return (
            <div style={{ textAlign: 'center' }}>
                <Helmet>
                    <title>Team and Individual Standings for the Minnesota High School Chess League</title>
                </Helmet>
                <h1>Standings</h1>

                <Dimmer active={active}>
                    <Loader>Loading</Loader>
                </Dimmer>

                <div style={{ display: 'inline-block' }}>
                    <div>
                        <Button.Group>
                            <Button color={selectedBtn === 0 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(0) }} style={{ border: '1px solid rosybrown' }}>Team - Season</Button>
                            {/*<Button color={selectedBtn === 100 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(100) }} style={{ border: '1px solid rosybrown' }}>Team - Playoffs</Button>*/}
                        </Button.Group>

                        <div style={{ paddingTop: '5px' }} />
                        <Button.Group>
                            <Button color={selectedBtn === 1 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(1) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>Ind-B1</Button>
                            <Button color={selectedBtn === 2 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(2) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>Ind-B2</Button>
                            <Button color={selectedBtn === 3 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(3) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>Ind-B3</Button>
                            <Button color={selectedBtn === 4 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(4) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>Ind-B4</Button>
                            <Button color={selectedBtn === 99 ? 'grey' : 'blue'} onClick={() => { this.handleButtonClick(99) }}
                                style={{ border: '1px solid rosybrown', padding: '10px' }}>Ind-Alt</Button>
                        </Button.Group>
                    </div>

                    {selectedBtn === 100 ?
                        (
                            <Table collapsing celled striped unstackable className="purple" style={{ display: 'inline-table' }} >
                                <Table.Header>
                                    <Table.Row className='td-white-ctr'>
                                        <Table.HeaderCell>Team Name</Table.HeaderCell>
                                        <Table.HeaderCell><span style={{ color: 'black' }}>Playoff<br />Matches</span></Table.HeaderCell>
                                        <Table.HeaderCell><span style={{ color: 'black' }}>Reg Season<br />Matches</span></Table.HeaderCell>
                                        <Table.HeaderCell><span style={{ color: 'black' }}>Playoff<br />Games</span></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.map(data, ({ teamId, teamName, color, matchTotal, gameTotal, regularSeasonMatchTotal }) => (
                                        <Table.Row key={teamId} style={{ backgroundColor: color }} className='td-white-ctr'>
                                            <Table.Cell><Link to={`/team/${teamId}`}>{teamName}</Link></Table.Cell>
                                            <Table.Cell style={{ color: 'black' }}>{matchTotal}</Table.Cell>
                                            <Table.Cell style={{ color: 'black' }}>{regularSeasonMatchTotal}</Table.Cell>
                                            <Table.Cell style={{ color: 'black' }}>{gameTotal}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>

                        ) : (

                            <Table sortable collapsing celled striped unstackable className="purple" style={{ display: 'inline-table' }} >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'tdHdr' : 'td-hide'}
                                            sorted={column === 'teamName' ? direction : null}
                                            onClick={this.handleSort('teamName')}
                                        >Team Name</Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'td-hide' : 'tdHdr'}
                                            sorted={column === 'playerName' ? direction : null}
                                            onClick={this.handleSort('playerName')}
                                        >Name</Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'tdHdr' : 'td-hide'}
                                            sorted={column === 'matchTotal' ? direction : null}
                                            onClick={this.handleSort('matchTotal')}
                                        >Matches</Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'tdHdr' : 'td-hide'}
                                            sorted={column === 'gameTotal' ? direction : null}
                                            onClick={this.handleSort('gameTotal')}
                                        >Games</Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'td-hide' : 'tdHdr'}
                                            sorted={column === 'gameTotal' ? direction : null}
                                            onClick={this.handleSort('gameTotal')}
                                        >Score</Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={selectedBtn === 0 ? 'tdHdr' : 'td-hide'}
                                            sorted={column === 'ptsDisplay' ? direction : null}
                                            onClick={this.handleSort('ptsDisplay')}
                                        >TB Pts</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.map(data, ({ teamId, playerId, playerName, teamName, ptsDisplay, color, matchTotal, gameTotal }) => (
                                        <Table.Row key={selectedBtn === 0 ? teamId : playerId} style={{ backgroundColor: color }}>
                                            <Table.Cell
                                                className={selectedBtn === 0 ? '' : 'td-hide'}>
                                                <Link to={`/team/${teamId}`}>{teamName}</Link>
                                            </Table.Cell>
                                            <Table.Cell
                                                className={selectedBtn === 0 ? 'td-hide' : ''}>
                                                <Link to={`/player/${playerId}`}>{playerName}</Link>
                                            </Table.Cell>
                                            <Table.Cell collapsing className={selectedBtn === 0 ? 'text-center' : 'td-hide'}>
                                                {matchTotal}
                                            </Table.Cell>
                                            <Table.Cell collapsing className={selectedBtn === 0 ? 'text-center' : 'td-hide'}>
                                                {gameTotal}
                                            </Table.Cell>
                                            <Table.Cell className={selectedBtn === 0 ? 'td-hide' : ''}>
                                                {gameTotal}
                                            </Table.Cell>
                                            <Table.Cell collapsing className={selectedBtn === 0 ? 'text-center' : 'td-hide'}>
                                                {ptsDisplay}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>

                        )}
                </div>

                <div style={{ display: 'all', paddingTop: '25px', paddingBottom: '50px' }} className="font-italic">
                    <div className={selectedBtn === 0 || selectedBtn === 100 ? '' : 'td-hide'}>
                        *TB Pts = Tiebreaker Points: This is determined by taking the game points scored against each opponent by their total match points. For each team, the bottom two opponents are dropped before calculating.
                    </div>
                </div>
                <div className="page-bottom" />
            </div>
        );
    }
}
